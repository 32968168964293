.bc{
    background: #bdc3c7;  /* fallback for old browsers */
    background: -webkit-linear-gradient(to right, rgba(44, 62, 80, 0), #1e2126);  /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right, rgba(44, 62, 80, 0), #1e2126); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

.bc-inv{
    background: #bdc3c7;  /* fallback for old browsers */
    background: -webkit-linear-gradient(to left, rgba(44, 62, 80, 0), #1e2126);  /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to left, rgba(44, 62, 80, 0), #1e2126); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}